import tree from './tree-large.png';
import './App.css';
import sange from './Sange';
import { HashRouter, Route, Link, Routes, useParams } from 'react-router-dom-v5-compat';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Julesange</h1>
        <img src={tree} className="App-logo" alt="logo" />
      </header>
      {
        <div id="Sange">
          <ul>{
            sange.map((sang, idx) =>
              <li>
                <Link className="Til" to={`/${idx}`}>{sang.title}</Link>
              </li>
            )
          }
          </ul>
        </div>
      }
    </div>
  );
}

function Sang() {
  const { id } = useParams();
  const sang = sange[id];
  const songStyle = { marginLeft: "6vmin" };
  return (<div>
    <Link className="Back" to="/">&larr;</Link>
    <div style={songStyle}>
      <h2>{sang.title}</h2>
      <ol>
        {sang.text.map(vers =>
          <li>
            {vers.split("<br>").map(l => <p>{l}</p>)}
          </li>
        )}
      </ol>
    </div>
  </div>
  );
}

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/:id" element={<Sang/>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
