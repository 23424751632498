
const sange = [
  {
    title: "Børn og voksne i kærlig krans",
    text: [
      "Børn og voksne i kærlig krans<br>rundt omkring træet vandre<br>alt under lysenes festlige glans<br>hånd i hånd med hverandre.",
      "Ak! om vi kunne føre enhver,<br>som er forladt og ene,<br>ind under lysenes rigdom her<br>under de grønklædte grene.",
      "Ak! om vi kunne med barnetro<br>fylde de hjerter, som græde!<br>så at de følte barnefro<br>julens velsignede glæde..."
    ]
  },
  {
    title: "Santa Lucia",
    text: ["Nu bæres lyset frem<br>stolt på din krone<br>rundt om i hus og hjem<br>sangen skal tone<br>nu på Lucia-dag<br>hilser vort vennelag<br>Santa Lucia, Santa Lucia",
      "Her ved vor ønskefest<br>sangen skal klinge<br>gaver til hver en gæst<br>glad vil du bringe<br>skænk os af lykkens væld<br>lige til livets kvæld<br>Santa Lucia, Santa Lucia"]
  },
  {
    title: "Nu har vi altså jul igen",
    text: ["Nu har vi altså jul igen, der la´r sig ikke skjule<br>og vi er vældig med på den, vi snakker kun om jule<br>om julesne og julegran og julelys og gaver<br>og julefest og julemad og julestads vi laver.<br>Ja, hvor man end sig vender hen, så er det jul og jul igen<br>og jul og jul og jul og jul og jul igen.",
      "Om julekort og julespøg og mosters julebagen,<br>og at man bliver julesløj på tredie juledagen<br>man være nok så juleglad, når juletræet stråler<br>den alt for gode julemad man ikke længere tåler.<br>Ja, hvor man end sig vender hen, så er det jul og jul igen<br>og jul og jul og jul og jul og jul igen.",
      "Nej, gåsesteg og risengrød og klejner, limonade<br>og marcipan og figenbrød, konfekt og chokolade<br>og julekage med sukat og mandler og rosiner,<br>det må og skal som resultat gi´ julemavepiner.<br>Ja, hvor man end sig vender hen, så er det jul og jul igen<br>og jul og jul og jul og jul og jul igen."]
  },
  {
    title: "På loftet sidder nissen",
    text: ["På loftet sidder nissen med sin julegrød,<br>sin julegrød, så god og sød,<br>han nikker, og han spiser, og han er så glad<br>for julegrød er hans bedste mad.<br>Men rundt omkring står alle de små rotter,<br>og de skotter, og de skotter:<br>\"Vi vil så gerne ha' lidt julegodter,\"<br>og de danser, danser rundt i ring.<br>",
      "Men nissefar han truer med sin store ske:<br>\"Vil I nu se at kom' af sted,<br>for jeg vil ha' min julegrød i ro og fred,<br>og ingen, ingen vil jeg dele med.\"<br>Men rotterne de hopper, og de danser,<br>og de svinser, og de svanser,<br>de kikker efter grøden, og de standser,<br>og de står om nissen tæt i ring.<br>",
      "Men nissefar han er en lille hidsigprop,<br>og med sin krop han gør et hop:<br>\"Jeg henter katten, hvis I ikke holder op,<br>når katten kommer, skal det nok bli' stop.\"<br>Så bliver alle rotterne så bange,<br>åh, så bange, åh, så bange,<br>de vender sig og danser et par gange,<br>og en, to, tre, så er de væk!<br>"]
  },
  {
    title: "Sneflokke kommer vrimlende",
    text: ["Sneflokke kommer vrimlende<br>hen over Diger trimlende,<br>det knyger ud af Himlene,<br>det sluger Hegn og Gaard,<br>det ryger ind ad Sprækkerne<br>til Pølserne paa Rækkerne,<br>og Faarene ved Hækkerne<br>faar Blink i Pelsens Haar.",
      "Og Poplerne bag Mønningen,<br>de duver dybt i Dønningen,<br>og over Stakke-Grønningen<br>omtrimler Kjærv og Neg,<br>det klaprer én om Ørerne,<br>fra Portene og Dørene,<br>bag hvilke de smaa Sørene'<br>har rustet sig til Leg.",
      "Og Gammelmor i Klokkerne<br>med Huen og Graalokkerne,<br>hun haler op i Sokkerne<br>og ser forsagt derud,<br>for nu er Kaalen liggende,<br>og nu staar Tjørnen stikkende<br>og spidder Sne paa Piggene,<br>og nu kom Kjørmes-Knud!"]
  },
  {
    title: "På Betlehems marker",
    text: ["På Betlehems marker i natten så <br>stille lå hyrderne blandt deres <br>hjorde på vagt; <br>da klared det hastigt, Guds engle så <br>milde stod for dem i Himmelens <br>skinnede dragt; <br>med strålende vinger godt budskab <br>de bringer: ”En glæde forkynder vi <br>eder så stor”. <br>Og englenes hære sang: ”Gud være <br>ære, en frelser er født på den <br>syndige jord.” <br>",
      "Det budskab nu længe har lyd over <br>jorden, at Jesus som frelser for <br>syndere kom. <br>Selv vi, som har hjemme heroppe i <br>Norden, har alle, o Herre, hørt tale derom.<br>Du, som bedst os kendte, dit ord til <br>at sendte, dit hjertelag mod os vi nu <br>kan forstå. <br>O, måtte dog mange ved englenes <br>sange få lyst med al nød hen til <br>Jesus at gå. <br>",
      "Den glæde skal folket på jord nu <br>erfare, hver sjæl, som vil komme, <br>kan tage imod <br>den gave fra Gud, som han lod <br>åbenbare, den ligger nu rede for <br>synderes fod. <br>Det mange har vovet, er saligt <br>hensovet i troen på Jesus og <br>hjemme hos Gud; <br>Dog flere sig vendte fra gaven, han <br>sendte, foragtede Frelseren, glemte <br>hans bud. <br>",
      "O, måtte dog vi, som endnu her må<br>vandre, med glæde ret lytte til <br>englenes sang, <br>at vi omkring tronen må skue <br>hverandre, når her vi har sluttet vor <br>pilegrimsgang. <br>Da frelste vi skulle lovprise til fulde <br>det barn, som i krybben i Betlehem lå; <br>må engang, I kære, hos Jesus vi <br>være, hans kærlighed bedre vi da <br>skal forstå.<br>"]
  },
  {
    audio: "www/audio/julelys.mp3",
    title: "Nu tændes tusind julelys", //
    text: ["Nu tændes tusind julelys<br>på jorden mørk og rund,<br>og tusind stjerner stråler smukt<br>på himlens dybblå grund.<br>",
      "Og over by og land i nat<br>går julens glade bud,<br>at født er Herren Jesus Krist<br>vor frelser og vor Gud.<br>",
      "Du stjerne over Betlehem<br>oh, lad dit milde lys<br>oplyse alt med håb og fred,<br>så kærligheden ses.<br>",
      "Lad hvert et hjerte, koldt og mørkt,<br>se strålen mild og blid,<br>en stråle af Guds kærlighed<br>i signed juletid.<br>"]
  },
  {
    title: "Vær velkommen, Herrens år (nytår)",
    text: ["Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Sandheds Gud, lad dit hellige ord<br>oplive, oplyse det høje Nord!<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Nådens Gud, lad dit solskin i vår<br>os skænke på marken et gyldent år!<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Fredens Gud! den livsalige fred<br>du skænke vort land til at blomstres ved!<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Fader Gud! os til glæde og gavn<br>nytåret henskride i Jesu navn!<br>Velkommen, nytår, og velkommen her!"]
  },
  {
    title: "Vær velkommen, Herrens år (jul)",
    text: ["Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Julenat, da Vorherre blev fød,<br>da tændte sig lyset i mørkets skød.<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Påskemorgen, da Herren opstod,<br>da livstræet fæsted i graven rod.<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Pinsedag, da Guds Ånd kom herned,<br>da nedsteg Guds kraft til vor skrøbelighed.<br>Velkommen, nytår, og velkommen her!<br>",
      "Vær velkommen, Herrens år,<br>og velkommen herhid!<br>Herrens år med vor Guds velbehag<br>nu bringer os glæde hver Herrens dag.<br>Velkommen, nytår, og velkommen her!"]
  },
  {
    title: "Velkommen igen, Guds engle små",
    text: ["Velkommen igen, Guds engle små,<br>fra høje himmelsale,<br>med dejlige solskinsklæder på,<br>i jordens skyggedale!<br>Trods klingrende frost godt år I spå<br>for fugl og sæd i dvale!<br>",
      "Vel mødt under sky på kirkesti,<br>på sne ved midnatstide!<br>Udbære vor jul ej nænner I,<br>derpå tør nok vi lide.<br>O, ganger dog ej vor dør forbi,<br>os volder ej den kvide!<br>",
      "Vor hytte er lav og så vor dør,<br>kun armod er derinde,<br>men gæstet I har en hytte før,<br>det drages vi til minde.<br>Er kruset af ler, og kagen tør,<br>deri sig engle finde!<br>",
      "Med venlige øjne himmelblå,<br>i vugger og i senge,<br>vi puslinger har i hver en vrå,<br>som blomster gro i enge.<br>O, synger for dem, som lærker slå,<br>som hørt de har ej længe!<br>",
      "Så drømme de sødt om Bethlehem,<br>og er det end forblommet,<br>de drømme dog sandt om barnets hjem,<br>som lå i krybberummet.<br>De drømme, de lege jul med dem,<br>hvis sang de har fornummet!<br>",
      "Da vågne de mildt i morgengry<br>og tælle mer ej timer!<br>Da høre vi julesang på ny,<br>som sig med hjertet rimer.<br>Da klinger det sødt i højen sky,<br>når juleklokken kimer!<br>",
      "Da vandre Guds engle op og ned<br>på salmens tonestige!<br>Da byder Vorherre selv Guds fred!<br>til dem, den efterhige!<br>Da åbner sig himlens borge-led,<br>da kommer ret Guds rige!<br>",
      "O, måtte vi kun den glæde se,<br>før vore øjne lukkes!<br>Da skal, som en barnemoders vé,<br>vor smerte sødt bortvugges!<br>Vor fader i himlen! lad det ske!<br>lad julesorgen slukkes!"]
  },
  {
    title: "Sikken voldsom trængsel og alarm",
    text: ["Sikken voldsom trængsel og alarm,<br>det er koldt, og man må gå sig varm.<br>Lygten tændes klokken fire alt,<br>det skal være aften med gevalt.<br>Midt på gaden sælges træ'r og frugt,<br>se butikken, hvor den stråler smukt!<br>Varer kan man få i tusindvis,<br>tænk dem bare: under indkøbspris.<br>Pris, pris, pris, pris, pris, pris,<br>tænk dem bare: under indkøbspris,<br>pris, pris, pris, pris, pris,<br>tænk dem bare: under indkøbspris!<br>",
      "Håndværksmanden han må holde her,<br>kunstneren har også sit besvær.<br>Og hans værker spredes vidt omkring,<br>takket være Hornemann og Bing!<br>Man af kundskabsvæsnet daglig ser,<br>at poeterne bli'r fler og fler.<br>Selv min lille bly forfrosne fugl<br>kvidrer med i dag, for det er jul.<br>Jul, jul, jul, jul, jul,<br>kvidrer med i dag, for det er jul,<br>jul, jul, jul, jul, jul,<br>kvidrer med i dag, for det er jul,<br>",
      "Pyntet smukt af en usynlig hånd<br>står nu træet der med lys og bånd.<br>Døren åbnes, og man strømmer ind,<br>kredsen sluttes med begejstret sind.<br>Børnene de hopper rask af sted,<br>bedstefader han er også med,<br>lad os tage del i deres sang,<br>brødre, vi var også børn engang.<br>Gang, gang, gang, gang, gang, gang,<br>brødre, vi var også børn engang,<br>gang, gang, gang, gang, gang,<br>brødre, vi var også børn engang.<br>",
      "Juleaften, o hvor er du sød,<br>så skal alle folk ha' risengrød,<br>æbleskiven bliver flittigt vendt,<br>gåsestegen er til bag'ren sendt.<br>Bonden sidder tidligt ved sit fad,<br>sikken Guds velsignelse af mad,<br>lænkehunden selv får dobbelt sul,<br>den skal også vide, det er jul.<br>Jul, jul, jul, jul, jul, jul,<br>den skal også vide, det er jul,<br>jul, jul, jul, jul, jul,<br>den skal også vide, det er jul.<br>",
      "Når man ikke er en doven krop,<br>står man julemorgen tidligt op.<br>Klokken kimer, gaden er så glat,<br>kirken lyser i den stille nat.<br>Indenfor er sang og festlig fred,<br>og man føler sig så vel derved,<br>allerhelst når præk'nen ikke du'r,<br>thi så får man sig en lille lur.<br>Lur, lur, lur, lur, lur, lur,<br>thi så får man sig en lille lur,<br>lur, lur, lur, lur, lur,<br>thi så får man sig en lille lur.<br>",
      "Op ad dagen går man byen rundt,<br>trækker vejret lidt, det er så sundt.<br>Alle folk er i den nye stads,<br>men om aftnen er der fint kalas.<br>Gud velsigne den, som først opfandt<br>det at lege jul og give pant.<br>Unge pige, lad os lege skjul,<br>giv mig kun et kys, det er jo jul.<br>Jul, jul, jul, jul, jul, jul,<br>giv mig kun et kys, det er jo jul,<br>jul, jul, jul, jul, jul,<br>giv mig kun et kys, det er jo jul.<br>",
      "Du som skænkte os den skønne fest,<br>hvad der båder os, det ved du bedst,<br>dog har jeg det håb, at - før jeg dør -<br>du ej nægter mig en vis favør:<br>Drej kun universet helt omkring,<br>vend kun op og ned på alle ting,<br>jorden med, thi den er falsk og hul,<br>rør blot ikke ved min gamle jul.<br>Jul, jul, jul, jul, jul, jul,<br>rør blot ikke ved min gamle jul,<br>jul, jul, jul, jul, jul,<br>rør blot ikke ved min gamle jul."]
  },
  {
    title: "Kimer, I klokker",
    text: ["Kimer, I klokker, ja, kimer før dag i det dunkle!<br>Tindrer, I stjerner, som englenes øjne kan funkle!<br>Fred kom til jord,<br>Himmelens fred med Guds ord.<br>Æren er Guds i det høje!<br>",
      "Julen er kommen med solhverv for hjerterne bange,<br>jul med Gudsbarnet i svøb under englenes sange,<br>kommer fra Gud,<br>bringer os glædskabens bud.<br>Æren er Guds i det høje!<br>",
      "Synger og danser og klapper i eders småhænder,<br>menneskebørnene alle til jorderigs ender!<br>Født er i dag<br>barnet til Guds velbehag.<br>Æren er Guds i det høje!"]
  },
  {
    title: "Julen har englelyd",
    text: ["Julen har englelyd,<br>vi med fryd<br>os barnlig på Gud Fader<br>forlader.<br>Vi var i nød så længe,<br>dog hørte han vor bøn,<br>han ved, hvortil vi trænge,<br>vor frelser blev Guds Søn.<br>Thi vil vi alle sjunge<br>med hjerte og med tunge:<br>et ære være Gud<br>for sit julebud!<br>",
      "Ret i en salig tid<br>kom han hid.<br>Til levende og døde<br>genløde<br>de ord, der for så vide<br>til alle tider hen<br>om ham, som tog vor kvide<br>og gav os fryd igen,<br>gik ned for os i døden,<br>som sol i aftenrøden,<br>og stod i morgengry<br>op for os på ny.<br>",
      "Sorg er til glæde vendt,<br>klagen endt.<br>Vor glæde skal hver tunge<br>udsjunge.<br>Syng med Guds engleskare<br>på Jesu fødselsdag<br>om fred trods nød og fare<br>Gud Faders velbehag,<br>som vil al sorgen vende<br>til glæde uden ende,<br>bær os på børnevis<br>ind i paradis."]
  },
  {
    title: "Julen har bragt velsignet bud", audio: "www/audio/Julen har bragt velsignet bud.mp3",
    text: ["Julen har bragt velsignet bud.<br>Nu glædes gamle og unge:<br>Hvad englene sang i verden ud,<br>nu alle små børn skal sjunge.<br>Grenen fra livets træ står skønt<br>med lys som fugle på kviste.<br>Det barn, som sig glæder fromt og kønt,<br>skal aldrig den glæde miste.<br>",
      "Glæden er jordens gæst i dag<br>med himmelkongen den lille.<br>Du fattige spurv! Flyv ned fra tag<br>med duen til julegilde!<br>Dans, lille barn, på moders skød!<br>En dejlig dag er oprunden:<br>I dag blev vor kære frelser fød<br>og paradisvejen funden.<br>",
      "Frelseren selv var barn som vi,<br>i dag han lå i sin vugge.<br>Den have, Guds engle flyve i,<br>vil Jesus for os oplukke.<br>Himmerigs konge blandt os bor,<br>han juleglæden os bringer,<br>han favner hver barnesjæl på jord<br>og lover os englevinger."]
  },

  {
    title: "Højt fra træets grønne top", audio: "www/audio/Hojt fra traets gronne top.mp3",
    text: ["Højt fra træets grønne top<br>stråler juleglansen,<br>spillemand, spil lystigt op,<br>nu begynder dansen.<br>Læg nu smukt din hånd i min,<br>ikke rør' ved den rosin!<br>Først skal træet vises,<br>siden skal det spises.<br>",
      "Se, børnlil, nu går det godt,<br>I forstår at trave,<br>lad den lille Sine blot<br>få sin julegave.<br>Løs kun selv det røde bånd!<br>Hvor du ryster på din hånd!<br>Når du strammer garnet,<br>kvæler du jo barnet.<br>",
      "Peter har den gren så kær,<br>hvorpå trommen hænger,<br>hver gang han den kommer nær,<br>vil han ikke længer.<br>Hvad du ønsker, skal du få,<br>når jeg blot kan stole på,<br>at du ej vil tromme,<br>før min sang er omme.<br>",
      "Anna hun har ingen ro,<br>før hun får sin pakke:<br>fire alen merino<br>til en vinterfrakke.<br>Barn, du bli'r mig alt for dyr,<br>men da du så propert syr,<br>sparer vi det atter<br>ikke sandt, min datter?<br>",
      "Denne fane, ny og god,<br>giver jeg til Henrik,<br>du er stærk, og du har mod,<br>du skal være fænrik.<br>Hvor han svinger fanen kækt!<br>Børn, I skylder ham respekt!<br>Vid, det er en ære<br>dannebrog at bære.<br>",
      "Træets allerbedste zir<br>skal min William have,<br>på det blanke guldpapir<br>må du gerne gnave.<br>Vær forsigtig og giv agt,<br>indenfor er noget lagt,<br>som du ej må kramme,<br>det er til din amme.<br>",
      "O, hvor den er blød og rar,<br>sikken dejlig hue,<br>den skal sikre bedstefa'r<br>imod frost og snue.<br>Lotte hun kan være stolt,<br>tænk jer, hun har garnet holdt;<br>det kan Hanne ikke,<br>hun kan bare strikke!<br>",
      "Børn, nu er jeg blevet træt,<br>og I får ej mere,<br>moder er i køkkenet,<br>nu skal hun traktere.<br>Derfor får hun denne pung,<br>løft engang, hvor den er tung!<br>Julen varer længe,<br>koster mange penge."]
  },

  {
    title: "Her kommer, Jesus, dine små",
    text: ["Her kommer, Jesus, dine små,<br>til dig i Betlehem at gå,<br>oplys enhver i sjæl og sind<br>at finde vejen til dig ind.<br>",
      "Vi løber dig med sang imod<br>og kysser støvet for din fod,<br>o salig stund, o klare nat,<br>da du blev født, vor sjæleskat!<br>",
      "Velkommen fra din himmelsal<br>til denne verdens tåredal,<br>hvor man dig intet andet bød<br>end stald og krybbe, kors og død.<br>",
      "Men Jesus, ak, hvor går det til<br>at dog så få betænke vil<br>den inderlige kærlighed,<br>som drog dig til vor jammer ned?<br>",
      "Så drag os ganske til dig hen,<br>o Jesus, du vor sjæleven,<br>at hver af os så inderlig<br>i troen må omfavne dig.<br>",
      "Lad verden ej med al sin magt<br>os rokke fra vor dåbes pagt,<br>men giv, at al vor længsel må<br>til dig, til dig alene stå!<br>",
      "Så skal det ske, at vi engang,<br>blandt alle helgens frydesang<br>i himlens søde paradis<br>skal prise dig på englevis.<br>",
      "Her står vi nu i flok og rad<br>om dig, vort skønne hjerteblad!<br>Ak, hjælp at vi og alle må<br>i himlen for din trone stå!"]
  },

  {
    title: "Glade jul, dejlige jul", audio: "www/audio/Glade jul.mp3",
    text: ["Glade jul, dejlige jul,<br>engle daler ned i skjul!<br>Hid de flyver med paradisgrønt,<br>hvor de ser, hvad for Gud er kønt,<br>lønlig iblandt os de går,<br>- lønlig iblandt os de går!<br>", "Julefryd, evige fryd,<br>hellig sang med himmelsk lyd!<br>Det er englene, hyrderne så,<br>dengang Herren i krybben lå,<br>evig er englenes sang,<br>- evig er englenes sang.<br>", "Fred på jord, fryd på jord,<br>Jesusbarnet blandt os bor!<br>Engle sjunger om barnet så smukt,<br>han har Himmerigs dør oplukt,<br>salig er englenes sang,<br>- salig er englenes sang.<br>", "Salig fred, himmelsk fred<br>toner julenat herned!<br>Engle bringer til store og små<br>bud om ham, som i krybben lå;<br>fryd dig, hver sjæl, han har frelst,<br>- fryd dig, hver sjæl, han har frelst!"]
  },
  {
    title: "En rose så jeg skyde",
    text: ["En rose så jeg skyde<br>op af den frosne jord,<br>alt som os fordum spåde<br>profetens trøsteord.<br>Den rose spired frem<br>midt i den kolde vinter<br>om nat ved Bethlehem.<br>", "For rosen nu jeg kvæder<br>omkap med Himlens hær:<br>en jomfru var hans moder,<br>Maria ren og skær.<br>I ham brød lyset frem<br>midt i den mørke vinter<br>om nat ved Bethlehem.<br>", "Den rose fin og lille<br>har dejlig duft og skær,<br>den lyse for os ville<br>og sprede mørket her.<br>I sandhed mand og Gud,<br>af syndens nød og pine<br>han nådig hjalp os ud."]
  },
  {
    title: "En lille nisse rejste",
    text: ["En lille nisse rejste<br>med ekstrapost fra land til land,<br>hans agt det var at hilse<br>på verdens største mand.<br>", "Han kom til stormogulen<br>og der, hvor kæmpekålen gror,<br>men mellem alle kæmper<br>ham tyktes ingen stor.<br>", "Da gik han ned til havet<br>og stirred i det klare vand,<br>han smilte, thi nu havde<br>han set den største mand."]
  },
  {
    title: "Det kimer nu til julefest", audio: "www/audio/Det kimer nu til julefest.mp3",
    text: ["Det kimer nu til julefest,<br>det kimer for den høje gæst,<br>som steg til lave hytter ned<br>med nytårsgaver: fryd og fred.<br>",
      "O, kommer med til Davids by,<br>hvor engle sjunger under sky,<br>o, ganger med på marken ud,<br>hvor hyrder hører nyt fra Gud!<br>",
      "Og lad os gå med stille sind<br>som hyrderne til barnet ind,<br>med glædestårer takke Gud<br>for miskundhed og nådesbud!<br>",
      "O Jesus, verden vid og lang<br>til vugge var dig dog for trang,<br>for ringe, om med guld tilredt<br>og perlestukken, silkebredt.<br>",
      "Men verdens ære, magt og guld<br>for dig er ikkun støv og muld,<br>i krybben lagt, i klude svøbt,<br>et himmelsk liv du har mig købt.<br>",
      "Velan, min sjæl, så vær nu glad,<br>og hold din jul i Davids stad,<br>ja, pris din Gud i allen stund<br>med liflig sang af hjertens grund!<br>",
      "Ja, sjunge hver, som sjunge kan:<br>Nu tændtes lys i skyggers land,<br>og ret som midnatshanen gol,<br>blev Jakobs stjerne til en sol!<br>",
      "Nu kom han, patriarkers håb,<br>med flammeord og himmeldåb,<br>og barnet tyder nu i vang,<br>hvad David dunkelt så og sang.<br>",
      "Kom, Jesus, vær vor hyttegæst,<br>hold selv i os din julefest,<br>da skal med Davidsharpens klang<br>dig takke højt vor nytårssang!"]
  },
  {
    title: "Den yndigste rose er funden",
    text: ["Den yndigste rose er funden,<br>blandt stiveste torne oprunden,<br>vor Jesus, den dejligste pode,<br>blandt syndige mennesker gro'de.<br>", "Alt siden vi tabte den ære,<br>Guds billedes frugter at bære,<br>var verden forvildet og øde,<br>vi alle i synden bortdøde.<br>", "Da lod Gud en rose opskyde<br>og sæden omsider frembryde,<br>at rense og ganske forsøde<br>vor slægts den fordærvede grøde.<br>", "Al verden nu burde sig fryde,<br>med salmer mangfoldig udbryde,<br>men mangen har aldrig fornummen,<br>at rosen i verden er kommen.<br>", "Forhærdede tidsel-gemytter,<br>Så stive som torne og støtter,<br>hvi holde I eder så ranke<br>i stoltheds fordærvede tanke.<br>", "Ak, søger de ydmyge steder,<br>i støvet for frelseren græder,<br>så få I vor Jesus i tale,<br>thi roserne vokse i dale.<br>", "Min Jesus, du altid skal være<br>mit smykke, min rose og ære,<br>de giftige lyster du døder<br>og korset så liflig forsøder.<br>", "Lad verden mig alting betage,<br>lad tornene rive og nage,<br>lad hjertet kun dåne og briste,<br>min rose jeg aldrig vil miste."]
  },
  { title: "Dejlig er jorden", audio: "www/audio/Dejlig er jorden.mp3", text: ["Dejlig er jorden,<br>prægtig er Guds himmel,<br>skøn er sjælenes pilgrimsgang!<br>Gennem de fagre<br>riger på jorden<br>gå vi til paradis med sang!<br>", "Tider skal komme,<br>tider skal henrulle,<br>slægt skal følge slægters gang.<br>Aldrig forstummer<br>tonen fra himlen<br>i sjælens glade pilgrimssang.<br>", "Englene sang den<br>først for markens hyrder,<br>skønt fra sjæl til sjæl det lød:<br>Fred over jorden!<br>Menneske, fryd dig,<br>os er en evig frelser fød!"] },
  {
    title: "Dejlig er den himmel blå", audio: "www/audio/Dejlig er den himmel bla.mp3",
    text: ["Dejlig er den himmel blå,<br>lyst det er at se derpå,<br>hvor de gyldne stjerner blinke,<br>hvor de smile, hvor de vinke<br>os fra jorden op til sig,<br>os fra jorden op til sig.<br>", "Det var midt i julenat,<br>hver en stjerne glimted mat,<br>men med ét der blev at skue<br>én så klar på himlens bue<br>som en lille stjernesol,<br>som en lille stjernesol.<br>", "Når den stjerne lys og blid<br>sig lod se ved midnatstid,<br>var det sagn fra gamle dage,<br>at en konge uden mage<br>skulle fødes på vor jord,<br>skulle fødes på vor jord.<br>", "Vise mænd fra østerland<br>drog i verden ud på stand<br>for den konge at oplede,<br>for den konge at tilbede,<br>som var født i samme stund,<br>som var født i samme stund.<br>", "De ham fandt i Davids hjem,<br>de ham fandt i Betlehem,<br>uden spir og kongetrone,<br>der kun sad en fattig kone,<br>vugged barnet i sit skød,<br>vugged barnet i sit skød.<br>", "Stjernen ledte vise mænd<br>til vor Herre Kristus hen;<br>vi har og en ledestjerne,<br>og når vi den følger gerne,<br>kommer vi til Jesus Krist,<br>kommer vi til Jesus Krist.<br>", "Denne stjerne lys og mild,<br>som kan aldrig lede vild,<br>er hans guddoms-ord det klare,<br>som han os lod åbenbare<br>til at lyse for vor fod,<br>til at lyse for vor fod.<br>"]
  },
  { title: "Blomstre som en rosengård", text: ["Blomstre som en rosengård<br>skal de øde vange,<br>blomstre i et gyldenår<br>under fuglesange,<br>mødes skal i stråledans<br>Libanons og Karmels glans,<br>Sarons yndigheder.<br>", "Ryste mer ej noget knæ,<br>ingens hænder synke,<br>skyde hvert udgået træ,<br>glatte sig hver rynke,<br>rejse sig det faldne mod,<br>rinde let uroligt blod,<br>frygt og sorg forsvinde!<br>", "Herren kommer, Gud med os,<br>troen på ham bier,<br>byde skal han fjenden trods,<br>som sit folks befrier;<br>alt betales på ét bræt:<br>fjenden sker sin fulde ret,<br>folket dobbelt nåde.<br>", "Skæres for den sorte stær<br>skal da øjne mange,<br>døve øren, fjern og nær,<br>høre frydesange,<br>som en hind da springer halt,<br>stammer, som for målløs gjaldt,<br>løfter klart sin stemme.<br>", "Så i Herrens helligdom<br>grant Esajas spå'de,<br>tiden randt, og dagen kom<br>med Guds lys og nåde,<br>med den Guds og Davids søn,<br>som gør end, i lys og løn,<br>paradis af ørke.<br>", "Ære med vor høje drot,<br>med hans Ånd tillige!<br>Sammen de gør alting godt<br>i vort himmerige,<br>døve, selv på gravens bred,<br>øren får at høre med,<br>stummes læber sjunge.<br>", "Højt bebude gyldenår<br>glade nytårssange:<br>Blomstre som en rosengård<br>skal de øde vange,<br>mødes skal i stråledans<br>Libanons og Karmels glans,<br>Sarons yndigheder!"] },
  { title: "Barn Jesus i en krybbe lå", text: ["Barn Jesus i en krybbe lå,<br>skønt Himlen var hans eje.<br>Hans pude her blev hø og strå,<br>mørkt var det om hans leje!<br>Men stjernen over huset stod,<br>og oksen kyssed barnets fod.<br>Halleluja! Halleluja! Barn Jesus!<br>", "Hver sorgfuld sjæl, bliv karsk og glad,<br>ryst af din tunge smerte,<br>et barn er født i Davids stad<br>til trøst for hvert et hjerte.<br>Til barnet vil vi stige ind<br>og blive børn i sjæl og sind.<br>Halleluja! Halleluja! Barn Jesus!<br>"] },
  { title: "Nu tænder vi det første lys", text: ["Nu tænder vi det første lys,<br>det må alene stå.<br>Vi venter på det lille barn,<br>som i en krybbe lå.<br>", "Nu tænder vi det andet lys,<br>da kan vi bedre se.<br>Vi venter på, at Gud, vor Far,<br>sin Søn vil sende ned.<br>", "Nu tænder vi det tredje lys,<br>det er et helligt tal.<br>Vi venter på en konge, som<br>skal fødes i en stald.<br>", "Nu tænder vi det fjerde lys,<br>og natten bli'r til dag.<br>Vi venter på en frelsermand<br>for alle folkeslag. <br>"] },
  { title: "Et barn er født i Bethlehem", audio: "www/audio/Et barn er fodt i Betlehem.mp3", text: ["Et barn er født i Bethlehem,<br>Bethlehem,<br>thi glæder sig Jerusalem.<br>Halleluja,<br>halleluja!<br>", "En fattig jomfru sad i løn,<br>sad i løn,<br>og fødte himlens kongesøn.<br>Halleluja,<br>halleluja!<br>", "Han lagdes i et krybberum,<br>krybberum,<br>Guds engle sang med fryd derom.<br>Halleluja,<br>halleluja!<br>", "Og østens vise ofred der,<br>ofred der,<br>Guld, røgelse og myrra skær.<br>Halleluja,<br>halleluja!<br>", "Forvunden er nu al vor nød,<br>al vor nød,<br>os er i dag en frelser fød.<br>Halleluja<br>halleluja!<br>", "Guds kære børn vi blev påny,<br>blev påny,<br>skal holde jul i himmelby.<br>Halleluja,<br>halleluja!<br>", "På stjernetæpper lyseblå,<br>lyseblå,<br>skal glade vi til kirke gå.<br>Halleluja<br>halleluja!<br>", "Guds engle der os lære brat,<br>lære brat,<br>at synge, som de sang i nat.<br>Halleluja,<br>halleluja!<br>", "Da vorde engle vi som de,<br>vi som de,<br>Guds milde ansigt skal vi se.<br>Halleluja,<br>halleluja!<br>", "Ham være pris til evig tid,<br>evig tid,<br>for frelser bold og broder blid!<br>Halleluja,<br>halleluja!"] },
  { title: "Der er noget i luften", audio: "www/audio/Der er noget i luften.mp3", text: ["Der er noget i luften<br>jeg véd ikke hvad,<br>som forår, skønt skoven<br>har mistet hvert blad,<br>der er noget i luften<br>som rosernes duften,<br>som fuglenes fryd,<br>skønt rosen er falmet,<br>  og fuglen<br>er draget mod syd.", "Der er noget i luften,<br>som gør mig så glad,<br>som trøster mit hjerte<br>i ungdommens bad,<br>der er noget herinde,<br>et strålende minde<br>med kærter og sang,<br>om julen derhjemme,<br>  derhjemme<br>hos moder en gang.<br>", "Der er noget i luften,<br>et barndommens bud,<br>som lyser imod mig<br>som stjernen fra Gud,<br>som leder mig stille<br>til frelseren lille<br>med barndommens fryd,<br>skønt barndommen<br>  flygted som fuglen,<br>der rejste mod syd.<br>", "O kom til os alle,<br>du højtidens drot,<br>om lokken er gylden,<br>om håret er gråt!<br>Udbred dine hænder,<br>mens lysene brænder,<br>og skænk os din fred,<br>thi evig, ja evig<br>  er glæden,<br>når du følger med."] },
  { title: "Nu tænder vi lyset", text: ["Nu tænder vi lyset, om Advent det melder<br>til hver som vil lytte, nu lyset fortæller<br>om Ham, der som barn lod til jorden sig føde,<br>så julelys tændes i mørke og øde.<br>", "Nu tænder vi lyset, velkommen vi byder<br>det år, hvor Han kommer, i ordet, som lyder.<br>Det lover, hvad end der i året vil komme,<br>Guds lys i det ny, som i det, der er omme.<br>", "Nu tænder vi lyset, med bøn det vi tænder,<br>at mørket må spredes og lysene brænde<br>med fred over lande og fred i vor kirke,<br>med fred over menneskers arbejde og virke.<br>", "Nu tænder vi lyset, det brænder så stille.<br>I bøn vi os bøjer! Alt ske som du ville.<br>Må Advent betyde: Til mig vil du komme<br>med lys over vejen til dagen er omme.<br>", "Nu tænder vi lys, det er Advent, Han kommer<br>engang som den store, retfærdige dommer,<br>og selv har Han bedt os beredte at være,<br>med lys i vor lampe gå ind til Hans ære.<br>"] },
  {
    title: "Kom alle kristne", text: [
      "Kom, alle kristne,<br>lad os sammen ile<br>til barnet i krybben i Betlehem.<br>Frem vil vi træde<br>for Guds Søn med glæde.<br>Kom, lad os ham tilbede,<br>kom, lad os ham tilbede,<br>kom, lad os ham tilbede<br>i Betlehem!<br>",
      "Syng, englehære,<br>giver Herren ære,<br>at han lod sig føde i Betlehem.<br>Lys er oprunden,<br>fred på jord er kommen.<br>Kom, lad os ham tilbede,<br>kom, lad os ham tilbede,<br>kom, lad os ham tilbede<br>i Betlehem!<br>",
      "Lov, pris og ære<br>skal for evigt være<br>vor hyldest til barnet i Betlehem.<br>Evigt Gud Fader<br>Himlens dør oplader.<br>Kom, lad os ham tilbede,<br>kom, lad os ham tilbede,<br>kom, lad os ham tilbede<br>i Betlehem!"]
  },
  {
    title: "Jeg er så glad hver julekvæld",
    text: [
      "Jeg er så glad hver julekvæld,<br>" +
      "for da blev Jesus født,<br>" +
      "da lyste stjernen som en sol,<br>" +
      "og engle sang så sødt.<br>",

      "Det lille barn i Betlehem,<br>" +
      "han var en konge stor,<br>" +
      "som kom fra Himlens høje slot<br>" +
      "ned til vor arme jord.",

      "Nu bor han højt i Himmerig,<br>" +
      "han er Guds egen Søn,<br>" +
      "men husker altid på de små<br>" +
      "og hører deres bøn.",

      "Jeg er så glad hver julekvæld,<br>" +
      "da synger vi hans pris.<br>" +
      "Da åbner han for alle små<br>" +
      "sit lyse Paradis.",

      "Jeg holder af hver julekvæld<br>" +
      "og af den Herre Krist,<br>" +
      "og at han elsker mig igen,<br>" +
      "det ved jeg ganske vist."
    ]
  },
  {
    title: "Det var ikke en nat som de andre",
    text: [
      "Det var ikke en nat som de andre,<br>" +
      "thi al skabningen holdt på sit vejr<br>" +
      "og så engle i tusindvis vandre<br>" +
      "rundt på jorden med stjernernes skær.<br>" +
      "Denne nat blev et lyshav i tiden,<br>" +
      "den har lyst gennem slægterne siden,<br>" +
      "dengang Gud selv blev menneskets lys.",

      "Det var ikke en himmel som andre,<br>" +
      "den blev åbnet af lys og af lyd,<br>" +
      "man så stjerner bevæges og vandre<br>" +
      "imod Betlehem, landet mod syd,<br>" +
      "så at hyrderne glemte at fryse,<br>" +
      "i Guds billede måtte de lyse,<br>" +
      "dengang Gud selv blev menneskets lys.",

      "Det var ikke et barn som de andre,<br>" +
      "der blev født under himlen den nat,<br>" +
      "det var Gud selv, der nu ville vandre<br>" +
      "for at finde på jorden sin skat.<br>" +
      "I den usleste mand eller kvinde<br>" +
      "så han perlen så kosteligt skinne,<br>" +
      "dengang Gud selv blev menneskets lys.",

      "Det var ikke en herre som andre,<br>" +
      "han fik ikke i herberget rum,<br>" +
      "måtte husvild imellem os vandre,<br>" +
      "til sin offerbænk førtes han stum,<br>" +
      "i den fremmedes grav blev han fange.<br>" +
      "I hans hus er der plads til de mange,<br>" +
      "han er Gud, han er menneskets lys.",

      "Det er ikke et ord som de andre,<br>" +
      "det kan styrte selv dødens tyran,<br>" +
      "det er lyset, hvorved vi skal vandre<br>" +
      "gennem mørket til morgenens land.<br>" +
      "Dette ord blev et lyshav i tiden,<br>" +
      "det har lyst gennem slægterne siden,<br>" +
      "det er Gud, det er menneskets lys."
    ]
  }
];

sange.sort(function (s1, s2) {
		var A = s1.title.toUpperCase();
		var B = s2.title.toUpperCase();
		return ((A < B) ? -1 :
				(A > B) ? +1 : 0);
		});

export default sange